import React from 'react';
import { FaGithub } from 'react-icons/fa';
import EmailForm from './emailForm.js'

import './style.scss';

import heroPicture from '../images/security.svg'
import Navbar from './navbar';

const Header = ({ siteTitle }) => (
	<section className="hero is-fullheight-with-navbar">
		<Navbar />
		<div className="hero-body gradientBg ">
			<div className="columns">
				<div className="column is-three-fifths-desktop is-mobile">
					<div className="hero-content content">
						<h1 className="is-size-1-desktop is-size-4-mobile has-text-white">
							Cloudcov
						</h1>
						<p className="subtitle has-text-white is-size-3-desktop is-size-5-mobile">
							Instantly detect and monitor malicious URLs submitted to your websites.   
						</p>
						<EmailForm />
						</div>
					</div>
				<div className="column is-mobile">
					<div className="media-right hero-image">
							<img 
								src={heroPicture} 
								alt="Cloudcov-Logo" 
							/>
					</div>
				</div>
			</div>
		</div>
	</section>
);

export default Header;
