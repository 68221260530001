import React from 'react'; 

import './style.scss'

const EmailForm = () => (
    <div>
        <form className="field is-grouped is-grouped-muliline" method="post" netlify-honeypot="bot-field" data-netlify="true">
            <input type="hidden" name="bot-field" />
            <div className="field is-grouped">
                <p className="control is-expanded">
                    <input className="input email-form" type="email" name="email" id="email" placeholder="me@gmail.com" required/>
                </p>
                <p className="control">
                    <button className="button is-dark is-medium-desktop is-small-mobile" type="submit">Early Access</button>
                </p>
            </div>
        </form>
    </div>
); 

export default EmailForm