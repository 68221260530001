import React from 'react';
import { FaGithub } from 'react-icons/fa';

import './style.scss';
import cloudcovLogo from '../images/cloudcov-header-logo.png';

const Navbar = () => (
		<nav className="navbar center" role="navigation" aria-label="main navigation">
			<div className="navbar-brand">
				
				<a className="navbar-item" href="https://cloudcov.com">
					<img 
							src={cloudcovLogo} 
							alt="Logo-1"
							width="112" 			
							height="28"
						/>
				</a>

				<a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
				</a>
			</div>

			{/* <div id="navbarBasicExample" class="navbar-menu">
				<div class="navbar-end">
					<div class="navbar-item">
						<div class="buttons">
						<a class="button is-dark">
							<strong>Sign up</strong>
						</a>
						<a class="button is-light">
							Log in
						</a>
						</div>
					</div>
				</div>
			</div> */}
		</nav>
);

export default Navbar;