import React from 'react';
import {
	FaSass,
	FaHtml5,
	FaReact,
	FaMobileAlt,
	FaRocket,
	FaOsi
} from 'react-icons/fa';

import './style.scss';
import Emoji from './emoji';

const FeatureSection = () => (
	<div className="is-mobile">
		
		<section className="section">
			<div className="container featuresection-container">
			<h1 className="center is-size-2 featuresection-title"> Protect your organization</h1>
				<div className="columns is-multiline">
					<div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
									<FaRocket size="fa-2x" color="#d22780" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4-desktop is-size-5-mobile">Immediate Detection</h1>
									<p className="subtitle is-size-5">
										The Cloudcov API enables teams to easily embed malicious URL detection directly into
										their applications. Get alerts via Slack and
										take action to proactively defend your organization against cyber threats.
									</p>
								</div>
							</div>
						</article>
					</div>
					<div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
									<FaOsi size="fa-2x" className="has-text-primary" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4-desktop is-size-5-mobile">Built for Developers</h1>
									<p className="subtitle is-size-5">
										Embedding security into applications is challenging. Cloudcov 
										is built with an obession over developer experience. Our aim is to create
										intuitive security tools for development teams. 
									</p>
								</div>
							</div>
						</article>
					</div>
					<div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
									<FaReact size="fa-2x" color="#5e227f" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4-desktop is-size-5-mobile">Continuous Monitoring</h1>
									<p className="subtitle is-size-5">
									As long as your application is running, you're protected by Cloudcov.  
									</p>
								</div>
							</div>
						</article>
					</div>
				</div>
			</div>
		</section>
	</div>
);

export default FeatureSection;